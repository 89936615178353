@import "./base.scss";

.scroll-lock {
  overflow: hidden;
}

/* header/navigation styling */
header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 16px 18px;
  z-index: 99;

  .header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      display: flex;
      width: 100%;
      color: white;
      align-items: center;

      > img {
        height: 48px;
        width: 48px;
        border-radius: 6px;
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
        margin-right: 12px;
      }

      > .logo__text {
        text-shadow: 1px 1px 2px black;
        font-size: 1.5em;
        font-weight: bold;
        font-family: $title-font;
        margin: 0;

        @media (max-width: 425px) {
          display: none;
        }
      }
    }

    .header-right {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 768px) {
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        right: -101vw;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        opacity: 0;
        background: var(--seafoamgreen);
        transition: right 0.5s ease-out, opacity 0.5s ease-out;
      }

      .link {
        margin: 0 12px;
        color: white;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 18px;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: 768px) {
          width: 100%;
          height: 100%;
          font-size: 5vw;

          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }

      .contact-button {
        text-shadow: none;
        background-color: var(--seafoamgreen);
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
        border-radius: 2px;

        @media (max-width: 768px) {
          margin-bottom: 0;
          box-shadow: none;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }
      }
    }

    .navButton {
      // display: none;

      @media (max-width: 768px) {
        display: flex;
        width: 54px;
        border-radius: 4px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 6px;
        padding: 10px;

        &:hover {
          background: rgba(255, 255, 255, 0.25);
          cursor: pointer;
        }

        > .line {
          height: 4px;
          width: 100%;
          border-radius: 2px;
          background: white;
        }
      }
    }

    .exitButton {
      height: 48px;

      @media (max-width: 768px) {
        position: absolute;
        top: 12px;
        right: 30px;

        * {
          box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
        }

        .line {
          width: 50%;
        }

        > .line1 {
          transform: rotate(45deg);
          position: absolute;
          top: 22px;
          left: 25%;
        }

        > .line2 {
          transform: rotate(-45deg);
          position: absolute;
          top: 22px;
          right: 25%;
        }
      }
    }
  }
  &.bg--show {
    background-color: var(--oceanblue);
  }
}

/* main contents styling */
#main {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
section,
header {
  width: 100%;
}

[class*="__content"] {
  @media (min-width: 1441px) {
    padding-left: calc((100vw - 1440px) / 2);
    padding-right: calc((100vw - 1440px) / 2);
  }
}

/* hero section */

#top {
  position: relative;
  background: linear-gradient(rgba(47, 123, 146, 0) 70%, rgba(47, 123, 146, 1)),
    url("../../assets/20201024_130132.jpg") center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;

  .backdrop-filter {
    background-color: rgba($color: #fff, $alpha: 0.1);
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(0.07rem);
  }

  * {
    color: white;
    text-shadow: 0px 0px 1em black, 0 0 1em rgba($color: #000000, $alpha: 0.1);
    margin: 0;
  }

  .top__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 5em;
    text-align: center;

    @media (max-width: 975px) {
      font-size: 4em;
    }

    @media (max-width: 768px) {
      font: size 3.25em;
    }

    @media (max-width: 576px) {
      font-size: 8vw;
    }

    @media (max-width: 425px) {
      font-size: 2em;
    }
  }

  .subheadline {
    margin-top: 12px;
    font-size: 2em;
    background-color: white;
    color: black;
    text-shadow: none;
    padding: 0 12px;
    font-family: $title-font;
    font-weight: bold;

    @media (max-width: 975px) {
      font-size: 1.75em;
    }

    @media (max-width: 768px) {
      font-size: 1.5em;
    }

    @media (max-width: 576px) {
      font-size: 4vw;
    }
  }
}

/* about section */
#about {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/about.jpg") center center;
  background-size: cover;
  overflow: hidden;
  min-height: 70vh;
  height: fit-content;
  padding-right: 12px;
  padding-top: 72px;
  padding-bottom: 0;
  color: white;
  padding-left: 54px;
  z-index: 2;

  @media (max-width: 975px) {
    color: black;
    height: fit-content;
    background: white;
    padding: 0;
  }

  .about__content {
    height: fit-content;
    overflow: hidden;
    padding-bottom: 0;
    color: white;
    z-index: 2;

    @media (max-width: 975px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: black;
      height: fit-content;
      background: white;
      padding: 0;
    }
  }

  img {
    position: relative;
    float: right;
    bottom: -5vh;
    height: calc(70vh - 72px);
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
    shape-outside: url("../../assets/headshot.png");
    shape-image-threshold: 0;
    shape-margin: 2.5%;

    @media (max-width: 975px) {
      display: none;
    }
  }

  h2 {
    font-size: 3em;
    margin-bottom: 24px;
    text-shadow: 0 0 24px rgba(0, 0, 0, 0.5);

    @media (max-width: 1265px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 975px) {
      text-shadow: none;
      font-size: 3em;
    }

    @media (max-width: 425px) {
      font-size: 10vw;
    }
  }

  p {
    position: relative;
    font-size: 1.25rem;
    text-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
    line-height: 1.25;
    z-index: 80;

    @media (max-width: 975px) {
      padding: 0 10%;
      text-shadow: none;
      font-size: 1.25rem;
    }
  }
  .mobile-image-container {
    display: none;

    @media (max-width: 975px) {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      height: 40vh;
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url("../../assets/about.jpg") center center;
      background-size: cover;
      overflow: hidden;

      > img {
        display: block;
        position: absolute;
        bottom: -24px;
        height: calc(100%);
      }
    }
  }
}

/* services section */
#services {
  width: 100%;
  position: relative;

  hr {
    position: relative;
    width: clamp(100px, 25vw, 500px);
    top: -25px;
    opacity: 0.5;
    height: 1px;
    display: none;

    @media (max-width: 975px) {
      display: block;
    }
  }

  .services__content {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;

    > .services__title {
      font-size: 3em;
      margin-top: 0;

      @media (max-width: 576px) {
        font-size: 8vw;
      }
    }

    .services__card-container {
      display: flex;
      width: 100%;
      height: 100%;
      /* min-height: fit-content; */

      @media (max-width: 768px) {
        flex-direction: column;
      }

      @media (min-width: 1441px) {
        gap: 24px;
      }
    }

    .service-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 36px;
      color: white;
      margin: 0 1px;
      justify-content: flex-start;
      height: auto;

      @media (max-width: 768px) {
        margin-bottom: 1%;
        align-items: flex-start;
        padding-left: 10%;
        padding-right: 10%;
      }

      @media (min-width: 1441px) {
        border-radius: 6px;
      }

      > img {
        height: 54px;
        width: 54px;

        @media (max-width: 768px) {
          align-self: center;
        }
      }

      > h3 {
        margin-bottom: 54px;
        text-align: center;

        @media (max-width: 768px) {
          align-self: center;
        }
      }
    }

    .bc {
      background: var(--seafoamgreen);
    }

    .g {
      background: var(--oceanblue);
      margin-right: 0;

      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    .service-list-item {
      margin-bottom: 5vh;
      font-size: 18px;
      line-height: 1.5;
    }
  }
}

/* contact section */
#contact {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/20201024_130132.jpg") center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  min-height: 87vh;
  position: relative;
  overflow: hidden;
  height: fit-content;
  padding-top: 72px;

  @media (max-width: 768px) {
    padding-top: 0;
    height: fit-content;
    justify-content: flex-start;
    min-height: 0;
  }

  .backdrop-filter {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100%;
    backdrop-filter: blur(10px);
  }

  #contact-form {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: fit-content;
    width: 75%;
    padding: 48px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 6px;
    margin: 12px 0;
    max-width: 672px;

    @media (max-width: 768px) {
      width: 100%;
      min-height: 100vh;
      height: fit-content;
      margin: 0;
      max-height: 1000px;
      border-radius: 0;
      padding-top: 96px;
      max-width: 768px;
    }

    .contact__title,
    button {
      align-self: center;
      margin-top: 0;
    }

    li {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: rgba(0, 0, 0, 0.75);
      margin-bottom: 18px;
    }

    .required-field-message {
      margin-top: 0;
      margin-bottom: 12px;
      color: var(--ctared);
      font-size: 0.75em;
    }

    .email-phone {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;

      @media (max-width: 576px) {
        flex-direction: column;
      }
    }

    .email-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 12px;

      @media (max-width: 576px) {
        margin-bottom: inherit;
      }
    }

    .phone-container {
      display: flex;
      flex-direction: column;
    }

    #phone-inputs {
      display: flex;
      flex-direction: row;
    }

    .contact-input {
      height: 2.5em;
      border: none;
      border-bottom: 2px rgba(0, 0, 0, 0.5) solid;
      background: transparent;
      padding: 0 6px;
      margin-top: 6px;
    }

    .contact-input:focus {
      outline: none;
      border-bottom: 3px solid var(--seafoamgreen);
    }

    .contact-input:focus ~ label {
      color: var(--seafoamgreen);
    }

    .contact-label #email {
      margin-right: 24px;
    }

    .textarea {
      min-height: 5em;
      resize: none;
    }

    #phone-inputs > .contact-input {
      text-align: center;
      margin-left: 6px;
      margin-right: 6px;
    }

    #phone-label > ::-webkit-input-placeholder {
      text-align: center;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }

    #phone-label > :-moz-placeholder {
      text-align: center;
    }

    #area,
    #prefix {
      width: 4em;
    }

    #suffix {
      width: 5em;
    }

    button {
      padding: 12px 48px;
      border: none;
      background-color: var(--seafoamgreen);
      border-radius: 2px;
      color: white;
      font-size: 1em;
      font-family: "Lato", san-serif;
      font-weight: 300;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

footer {
  background-color: var(--seafoamgreen);
  padding: 0 12px;
  color: white;
  margin-top: -50px;

  .footer__content {
    display: flex;
    align-content: center;
    justify-content: space-between;

    > p {
      @media (max-width: 425px) {
        font-size: clamp(12px, 3vw, 1rem);
      }
    }
  }
}
