/* imports */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --lightblue: #71b4cf;
  --seafoamgreen: #4095a0;
  --oceanblue: #2f7b92;
  --ctared: #ff4400;
}
/* boilerplate styling */
html {
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Proza Libre", sans-serif;
}

li,
p,
div,
ul,
ol,
nav,
form {
  font-family: "Lato", sans-serif;
}

$title-font: "Proza Libre", sans-serif;
$body-font: "Lato", sans-serif;
